@font-face {
    font-family: 'montserratbold';
    src: url('../fonts/montserrat-bold-webfont.woff2') format('woff2'),
         url('../fonts/montserrat-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'montserratregular';
    src: url('../fonts/montserrat-regular-webfont.woff2') format('woff2'),
         url('../fonts/montserrat-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

.color_white{
  background-color: #fff;
}

.text_white{
  color: #fff;
}

.textBoxCon .MuiInputLabel-animated{
  font-family: 'montserratregular';
}

*{
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
}
body{
  
}
.fa-primary{
  color: #1A73E8;
}
.fa-secondary{
  color: rgba(255, 255, 255, 0.4)
}

#root{
  background: url("../images/banner.jpg") no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.header{
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  background: #080a0b;
}
.header .navbar {
  background: none !important;
  border-bottom: solid 1px #fff;
  padding: 0 80px;
}
.header .navbar-light .navbar-nav .nav-link {
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  padding: 24px 0;
  margin: 0 25px;
  transition: all linear 0.2s 0s;
  position: relative;
  cursor: pointer;
}

.header .navbar-light .navbar-nav .active .nav-link{
  color: #fff;
}
.header .navbar-light .navbar-nav .nav-link:after{
  content: '';
  position: absolute;
  width: 100%;
  height: 3px;
  background: #5B4FEA;
  bottom: -2px;
  left: 0;
  transition: all linear 0.1s 0s;
  transform: scale(0);
}
.header .navbar-light .navbar-nav .nav-link:hover{
  color: #fff;
}
.header .navbar-light .navbar-nav .nav-link:hover:after{
  transform: scale(1);
}
.header .navbar-light .navbar-nav .active .nav-link:after{
  transform: scale(1);
}

.section{
  min-height: 100vh;
  padding: 100px 0 50px 0 ;
  display: flex;
  flex-direction: column;
  position: relative;
}
.topBanner{
  display: flex !important;
  min-height: 100vh;
  align-items: center;
  position: relative;
}

.banner-caption{
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 60px;
}

.banner-caption h2 {
  font-size: 70px;
  font-weight: 500;
  line-height: 82px;
  color: #fff;
  position: relative;
  margin-bottom: 30px;
  max-width: 800px;
}

.banner-caption h2:after {
  content: '';
  position: absolute;
  height: 3px;
  width: 95px;
  background: #1A73E8;
  bottom: -15px;
  left: 0;
}

.banner-caption h5 {
  font-size: 34px;
  line-height: 40px;
  color: #fff;
  max-width: 800px;
  font-weight: 400;
}

.heading{
  font-size: 44px;
  font-weight: 400;
  line-height: 51px;
  color: #fff;
  position: relative;
  margin-bottom: 50px;
  max-width: 700px;
  text-transform: capitalize;
}

.heading:after{
  content: '';
  position: absolute;
  height: 3px;
  width: 95px;
  background: #1A73E8;
  bottom: -15px;
  left: 0;
}

.servicesList{
  margin-top: 90px;
}

.servicesList ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
.servicesList ul li{
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 20px;
  padding: 20px 18px;
  display: flex;
  flex: 1;
  margin: 10px 8px;
  flex-direction: column;
  transition: all linear 0.3s 0s;
  position: relative;
}
.servicesList ul li:hover{
  box-shadow: 0px 0px 20px #535151;
}

.servicesList ul li h5{
  font-size: 18px;
  color: #fff;
  font-weight: 700;
  margin-bottom: 16px;
  line-height: 21px;
  max-width: 145px;
}
.servicesList ul li p{
  font-size: 14px;
  font-weight: 300;
  color: #fff;
  margin-bottom: 80px;
}
.servicesList ul li .ser_icon {
  height: 40px;
  margin-bottom: 48px;
}
.servicesList ul li .ser_icon svg{
  width: 56px;
}

.text_content{
  margin-top: 90px;
}
.text_content p{
  font-size: 18px;
  font-weight: 300;
  color: #fff;
  line-height: 21px;
  margin-bottom: 25px;
}


.teamSection{

}
.teamSection ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: flex-start;
  /*justify-content: space-between;*/
}
.teamSection ul li{
  display: flex;
  flex-direction: column;
  max-width: 434px;
  margin: 0 100px;
}
.teamSection ul li .teamImg{
  width: 140px;
  height: 140px;
  margin: auto;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 30px;
}
.teamSection ul li .teamImg img{
  width: 100%;
}
.teamSection ul li h4{
  font-size: 38px;
  color: #fff;
  font-weight: 400;
  margin-bottom: 5px;
  text-align: center;
}
.teamSection ul li h5{
  font-size: 24px;
  text-align: center;
  font-weight: 400;
  color: #fff;
}
.teamDes{
  margin-top: 50px;
}
.teamDes p{
  font-weight: 300;
  font-size: 18px;
  line-height: 21px;
  color: #fff;
}


footer{
  background: #1A73E8;
  padding: 50px 80px 20px;
}
footer h4{
  font-size: 20px;
  color: #fff;
  margin-bottom: 14px;
  font-weight: 400;
}
footer p{
  font-size: 13px;
  color: #fff;
  line-height: 15px;
}

.contactInfo{

}
.contactInfo .heading {
  margin-bottom: 30px;
}
.contactInfo p{
  font-size: 24px;
  color: #fff;
  line-height: 28px;
}
.contactInfo ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.contactInfo ul li {
  margin-bottom: 30px;
  color: #fff;
  font-size: 18px;
  font-weight: 400;
  line-height: 21px;
}

.contactForm .MuiFormControl-root{
  width: 100%;
  margin-bottom: 10px;
}
.contactForm .MuiInputBase-input{
  color: #fff;
  font-family: 'Roboto';
}
.contactForm .MuiFormLabel-root{
  color: #fff;
  font-size: 16px;
  font-weight: 400;
}
.contactForm{
  margin-bottom: 50px;
}
.contactForm .MuiInput-underline:before{
  border-color: rgba(255, 255, 255, 0.42);
}
.contactForm .formBtn{
  margin-top: 20px;
}
.contactForm .MuiButton-containedPrimary{
  background: #1A73E8;
}

.fotterForm .MuiFormLabel-root{
  color: #fff;
  font-size: 16px;
  font-weight: 400;
}
.fotterForm .MuiInput-underline:before{
  border-color: rgba(255, 255, 255, 0.42);
}
.fotterForm .MuiFormControl-root{
  width: 100%;
  margin-bottom: 20px;
}
.mapBox{
  clear: both;
}
.mapBox iframe {
  width: 100%;
  height: 300px;
  border: none;
  filter: invert(90%);
}

.service_slider{
  margin: 0 30px ;
  margin-top: 80px;
}
.service_slider .owl-stage{
  display: flex;
}
.service_slider .owl-item{
  display: flex;
}
.service_slider .item{
  text-align: center;
  display: flex;
  padding: 0 15px;
  flex-direction: column;
  margin: auto;
}
.service_slider .owl-carousel .owl-item img{
  width: auto;
  margin: auto;
  max-width: 100%;
}
.service_slider .item h4{
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  text-align: center;
  color: #fff;
  margin-top: 10px;
}
.service_slider .owl-carousel .owl-nav button.owl-prev, .service_slider .owl-carousel .owl-nav button.owl-next {
  position: absolute;
  height: 25px;
  background: url('../images/slider_arrow.png') no-repeat;
  font-size: 0;
  width: 15px;
  top: calc(50% - 15px);
  left: -30px;
}
.service_slider .owl-carousel .owl-nav button.owl-next{
  left: auto;
  right: -30px;
  transform: rotate(180deg);
}



.testimonial_slider{

}
.testimonial_slider .item h4{
  font-size: 46px;
  line-height: 56px;
  font-weight: 400;
}
.testimonial_slider .item h5{
  color: #fff;
  font-size: 25px;
  line-height: 30px;
  margin-top: 20px;
  font-weight: 300;
}
.testimonial_slider .item h5 b{
  font-weight: 500;
}
.testimonial_slider .owl-stage-outer .owl-item{
  padding: 0 50px;
}

.testimonial_slider .owl-carousel .owl-nav button.owl-prev, .testimonial_slider .owl-carousel .owl-nav button.owl-next {
  height: 40px;
  width: 22px;
  background-size: 22px 40px;
  top: calc(50% - 50px);
}

.testimonial_slider .owl-theme .owl-dots .owl-dot span{
  width: 8px;
  height: 8px;
  margin: 3px;
}
.testimonial_slider .owl-theme .owl-dots .owl-dot.active span{
  background: #007DFF;
}

.quoteIcon{
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background: #1A73E8 url('../images/quote.png') center no-repeat;
  margin: 0 auto 30px;

}
.sliderLine{
  border-color: #1A73E8;
  margin-top: 50px;
}

.goToitem{
  position: absolute;
  top: -50px;
}

.arrow-icon-container{
  position: absolute;
  right: 15px;
  bottom: 15px;
  display: none;
}

.toggleMenu.navbar-toggler{
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999999;
  background: #fff;
  margin: 18px 15px;
  font-size: 15px;
}

.imageHeader{
  width: 370px;
}

.text_bottom{
    position: absolute;
    width: 100%;
    bottom: 25px;
}

.button_fotter.btn.btn-secondary{
  background: white;
  color: #000000;
  font-size: 14px;
  padding: 10px 20px;
}

@media screen and (max-width:991px) {
  .imageHeader{
    width: 300px;
  }
  .header .navbar-light .navbar-nav .nav-link {
    font-size: 14px;
    font-weight: 500;
    padding: 18px 0;
    margin: 0 14px;
  }
  .servicesList li{
    position: relative;
    min-width: 200px;
    max-width: 200px;
  }
}

@media screen and (max-width:767px) {
  .header .navbar-light   {
    background: rgba(18, 18, 18, 0.99) !important;
    border-bottom: solid 1px #fff;
  }
  .imageHeader{
    width: 300px;
  }
  .toggleMenu.navbar-toggler{
    margin: 12px 12px;
  }
  .banner-caption h2 {
    font-size: 40px;
  }
  .banner-caption h5 {
    font-size:25px;
  }
  .footor_right{
    margin-top: 30px;
  }
  .text_bottom{
    bottom: -30px;
  }
  footer{
    padding: 40px 80px 80px 40px;
  }
  .section{
    padding: 100px 10px 50px 10px ;
  }
  .testimonial_slider .item h4{
    font-size: 24px;
  }
  .testimonial_slider .item h5{
    font-size: 15px;
  }
  .heading{
    font-size: 36px;
  }
  .teamSection ul li{
    padding: 10px;
  }
  .teamSection ul li h4{
    font-size: 30px;
  }
  .teamSection ul li h5{
    font-size: 20px;
  }
}

.socialMedia{
  list-style: none;
  padding: 0;
  margin: 0;
}
.socialMedia li{
  display: inline-block;
  margin-right: 28px;
}


.Toastify__toast-container .Toastify__toast--default{
  background: #1A73E8;
  color: #fff;
  border-radius: 5px;
  min-height: 50px;
}
.Toastify__toast-container .Toastify__close-button{
  font-size: 22px;
  color: #fff;
}

.Toastify__toast-container .Toastify__toast--error {
  color: #fff;
  border-radius: 5px;
  min-height: 50px;
  background: #FF9800;
}



.privacyAccept{
  position: absolute;
  width: 724px;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.2);
  bottom: 120px;
  left: 24px;
  padding: 15px 45px;
  z-index: 10;
}
.privacyAccept h4{
  font-size: 22px;
  color: #fff;
  font-weight: 700;
  margin-bottom: 14px;
}
.privacyAccept p{
  font-size: 12px;
  line-height: 14px;
  color: #fff;
}
.privacyAccept p a{
  border-bottom: solid 1px #fff;
  text-decoration: none;
  color: #fff;
}
.privacyAccept p.acceptLink{
  text-align: right;
  margin-bottom: 0;
}
.privacyAccept p.acceptLink a{
  font-size: 14px;
  font-weight: 500;
  line-height: 26px;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
}